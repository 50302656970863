import type { FunctionComponent } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";

import { query } from "scmp-app/components/header/header-oneline-menu-container";
import { useEditionValue } from "scmp-app/lib/edition";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { headerOnelineMenuContainerQuery } from "scmp-app/queries/__generated__/headerOnelineMenuContainerQuery.graphql";
import type { headerOnelineMenuStyleQuery$key } from "scmp-app/queries/__generated__/headerOnelineMenuStyleQuery.graphql";

import { Container, StyledEntityLink, StyledSectionStyleHeaderMenuItem } from "./styles";

export type Props = {
  queryReference: PreloadedQuery<headerOnelineMenuContainerQuery>;
};

export const HeaderOnelineMenuStyle: FunctionComponent<Props> = ({ queryReference }) => {
  const data_ = usePreloadedQuery(query, queryReference);

  const data = useFragment<headerOnelineMenuStyleQuery$key>(
    graphql`
      fragment headerOnelineMenuStyleQuery on Query {
        styleOnelineMenu: queue(filter: { name: "scmp_pwa_oneline_menu_style" }) {
          items(first: 6) {
            edges {
              node {
                ... on Section {
                  ...entityLink
                  ...sectionStyleHeaderMenuItem
                }
              }
            }
          }
        }
      }
    `,
    data_,
  );

  const edition = useEditionValue({ initializeWithValue: true });
  const currentPageType = useCurrentPageType();
  return (
    <Container>
      {data.styleOnelineMenu?.items?.edges.map((edge, index) => (
        <StyledEntityLink
          key={index}
          query={{
            module: `oneline_menu_section_${edition}`,
            pgtype: currentPageType,
          }}
          reference={edge.node}
        >
          <StyledSectionStyleHeaderMenuItem reference={edge.node} />
        </StyledEntityLink>
      ))}
    </Container>
  );
};

HeaderOnelineMenuStyle.displayName = "HeaderOnelineMenuStyle";
