import { notEmpty } from "@product/scmp-sdk";
import type { FunctionComponent } from "react";

import type { Props as BaseEntityLinkProps } from "scmp-app/components/base-entity-link";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { application, section } from "scmp-app/data";
import { useEditionValue } from "scmp-app/lib/edition";

import LogoChinaPower from "./logo-china-power.svg";
import LogoOneHundredTopTables from "./logo-one-hundred-top-tables.svg";
import LogoPosties from "./logo-posties.svg";
import LogoScmpLive from "./logo-scmp-live.svg";
import LogoScmpPlus from "./logo-scmp-plus.svg";
import LogoStyle from "./logo-style.svg";
import LogoTheStore from "./logo-the-store.svg";
import LogoYoungPost from "./logo-young-post.svg";
import { Container, StyledBaseEntityLink } from "./styles";

const scmpOneLineMenuAllUTMSource = "scmp_oneline_menu_all";

const logoList = [
  {
    component: LogoScmpPlus,
    linkEntity: application.scmpPlus,
    tracking: "plus",
  },
  {
    component: LogoChinaPower,
    linkEntity: application.chinaPowerPlayers,
    tracking: "china's power players",
  },
  {
    component: LogoStyle,
    linkEntity: section.style,
    tracking: "style",
  },
  {
    component: LogoOneHundredTopTables,
    customizedQueryParameters: {
      utm_source: scmpOneLineMenuAllUTMSource,
    },
    linkEntity: application.oneHundredTopTables,
    tracking: "100 top tables",
  },
  {
    component: LogoYoungPost,
    customizedQueryParameters: {
      utm_source: scmpOneLineMenuAllUTMSource,
    },
    linkEntity: application.yp,
    tracking: "young post",
  },
  {
    component: LogoPosties,
    customizedQueryParameters: {
      utm_source: scmpOneLineMenuAllUTMSource,
    },
    linkEntity: application.posties,
    tracking: "posties",
  },
  {
    component: LogoTheStore,
    linkEntity: application.theStore,
    tracking: "the store",
  },
  {
    component: LogoScmpLive,
    customizedQueryParameters: {
      utm_source: scmpOneLineMenuAllUTMSource,
    },
    linkEntity: application.scmpLive,
    tracking: "scmp live",
  },
];

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
};

export const HeaderAllMenuApplications: FunctionComponent<Props> = ({
  className,
  queryParameter,
}) => {
  const edition = useEditionValue();
  return (
    <Container className={className}>
      {logoList.map((logo, index) => (
        <StyledBaseEntityLink
          key={index}
          linkEntity={logo.linkEntity}
          query={
            notEmpty(logo.customizedQueryParameters)
              ? logo.customizedQueryParameters
              : queryParameter
          }
          onClick={() => {
            sendGA4Tracking({
              action: "click",
              category: "menu",
              customized_parameters: {
                action_type: "redirect",
                edition,
                icon: logo.tracking,
                menu_type: "mega",
              },
              subcategory: "section",
            });
          }}
        >
          <logo.component />
        </StyledBaseEntityLink>
      ))}
    </Container>
  );
};

HeaderAllMenuApplications.displayName = "HeaderAllMenuApplications";
