import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "scmp-app/components/base-entity-link";
import {
  HeaderAllMenuItemBaseLink,
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import type { headerAllMenuSectionAppConfigQuery$key } from "scmp-app/queries/__generated__/headerAllMenuSectionAppConfigQuery.graphql";

import { Container, ItemContainer } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuSectionAppConfigQuery$key;
};

export const HeaderAllMenuSection: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const appConfig = useFragment(
    graphql`
      fragment headerAllMenuSectionAppConfigQuery on Query {
        onelineMenu: appConfig(filter: { entityId: "scmp_pwa_oneline_menu" }) {
          json
        }
      }
    `,
    reference_,
  );

  const onelineMenuAppConfig = appConfig?.onelineMenu?.json as ScmpPwaOnelineMenuAppConfig;
  const allList = onelineMenuAppConfig?.all;
  const edition = useEditionValue();
  if (!allList) return null;

  return (
    <Container className={className}>
      {allList.map((listItem, index) => (
        <ItemContainer key={index}>
          <HeaderAllMenuTitle>{listItem.name}</HeaderAllMenuTitle>
          <HeaderAllMenuItemLinkContainer>
            {listItem.items.map((link, index) => (
              <HeaderAllMenuItemBaseLink
                key={index}
                linkEntity={link}
                query={queryParameter}
                onClick={() => {
                  const computeDestination = () => {
                    if (link.name.toLowerCase() === "latest") {
                      return `${listItem.name.toLowerCase()}_latest`;
                    }
                    return link.name;
                  };
                  sendGA4Tracking({
                    action: "click",
                    category: "menu",
                    customized_parameters: {
                      action_type: "redirect",
                      destination: computeDestination(),
                      edition,
                      menu_type: "mega",
                    },
                    subcategory: "section",
                  });
                }}
              >
                {link.name}
              </HeaderAllMenuItemBaseLink>
            ))}
          </HeaderAllMenuItemLinkContainer>
        </ItemContainer>
      ))}
    </Container>
  );
};

HeaderAllMenuSection.displayName = "HeaderAllMenuSection";
