import type { FunctionComponent } from "react";
import type { PreloadedQuery } from "react-relay";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";
import "swiper/css";
import "swiper/css/navigation";

import { query } from "scmp-app/components/header/header-oneline-menu-container";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import { useCurrentPageType } from "scmp-app/lib/router/hooks";
import type { headerOnelineMenuAppConfigQuery$key } from "scmp-app/queries/__generated__/headerOnelineMenuAppConfigQuery.graphql";
import type { headerOnelineMenuContainerQuery } from "scmp-app/queries/__generated__/headerOnelineMenuContainerQuery.graphql";

import {
  Container,
  MenuItem,
  StyledBaseEntityLink,
  StyledSwiper,
  StyledSwiperSlide,
} from "./styles";

export type Props = {
  queryReference: PreloadedQuery<headerOnelineMenuContainerQuery>;
};

export const HeaderOnelineMenu: FunctionComponent<Props> = ({ queryReference }) => {
  const data_ = usePreloadedQuery(query, queryReference);

  const data = useFragment<headerOnelineMenuAppConfigQuery$key>(
    graphql`
      fragment headerOnelineMenuAppConfigQuery on Query {
        onelineMenu: appConfig(filter: { entityId: "scmp_pwa_oneline_menu" }) {
          json
        }
      }
    `,
    data_,
  );

  const onelineMenuAppConfig = data?.onelineMenu?.json as ScmpPwaOnelineMenuAppConfig;
  const edition = useEditionValue({ initializeWithValue: true });
  const menuItems = onelineMenuAppConfig?.list[edition];

  const currentPageType = useCurrentPageType();

  if (!onelineMenuAppConfig || !menuItems) return null;

  return (
    <Container>
      <StyledSwiper cssMode={false} pagination={false} slidesPerView="auto">
        {menuItems?.map((item, index) => (
          <StyledSwiperSlide key={index}>
            <StyledBaseEntityLink
              linkEntity={item}
              query={{
                module: `oneline_menu_section_${edition}`,
                pgtype: currentPageType,
              }}
              onClick={() => {
                const computeDestination = () => {
                  const name = item.trackingName ?? item.name;
                  if (name.toLowerCase() === "latest") {
                    return "news_latest";
                  }
                  if (name.toLowerCase() === "twia") {
                    return "This Week In Asia";
                  }
                  return name;
                };
                sendGA4Tracking({
                  action: "click",
                  category: "menu",
                  customized_parameters: {
                    action_type: "redirect",
                    destination: computeDestination(),
                    edition,
                    menu_type: "oneline",
                  },
                  subcategory: "section",
                });
              }}
            >
              <MenuItem>{item.name}</MenuItem>
            </StyledBaseEntityLink>
          </StyledSwiperSlide>
        ))}
      </StyledSwiper>
    </Container>
  );
};

HeaderOnelineMenu.displayName = "HeaderOnelineMenu";
