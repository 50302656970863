import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "scmp-app/components/base-entity-link";
import { HeaderAllMenuTitle } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common/header-mega-menu-title";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import type { headerAllMenuColumnistsQuery$key } from "scmp-app/queries/__generated__/headerAllMenuColumnistsQuery.graphql";

import {
  AuthorName,
  Container,
  LinkContainer,
  StyledAuthorAvatar,
  StyledEntityLink,
} from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuColumnistsQuery$key;
};

export const HeaderAllMenuColumnists: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const queue = useFragment(
    graphql`
      fragment headerAllMenuColumnistsQuery on Query {
        columnistsQueue: queue(filter: { name: "scmp_columnists" }) {
          items(first: 7) {
            edges {
              node {
                ... on Author {
                  ...entityLink
                  ...authorAvatarAuthor
                  name
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const edition = useEditionValue();

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>SCMP Columnists</HeaderAllMenuTitle>

      <LinkContainer>
        {queue.columnistsQueue?.items?.edges.map((edge, index) => (
          <StyledEntityLink
            key={index}
            query={queryParameter}
            reference={edge.node}
            onClick={() => {
              sendGA4Tracking({
                action: "click",
                category: "menu",
                customized_parameters: {
                  action_type: "redirect",
                  columnist: edge.node.name ?? "",
                  edition,
                  menu_type: "mega",
                },
                subcategory: "section",
              });
            }}
          >
            <StyledAuthorAvatar
              height={40}
              reference={edge.node}
              width={40}
              disableLinkingData
              withoutLink
            />
            <AuthorName>{edge.node.name}</AuthorName>
          </StyledEntityLink>
        ))}
      </LinkContainer>
    </Container>
  );
};

HeaderAllMenuColumnists.displayName = "HeaderAllMenuColumnists";
