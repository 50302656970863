import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { fontPlayfairDisplay } from "@product/scmp-sdk";

import { HeaderAllMenu } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu";
import { ToggleContainer } from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/styles";

export const StyledHeaderAllMenu = styled(HeaderAllMenu)``;

type Props = {
  $variant?: string;
};

export const Container = styled.div<Props>`
  display: flex;
  align-items: center;

  margin-inline-start: 32px;

  overflow: hidden;

  ${props => {
    if (props.$variant === "scmp/magazines-style") {
      return css`
        ${ToggleContainer} {
          span {
            color: #222222;
            font-weight: normal;
            font-size: 14px;
            font-family: ${fontPlayfairDisplay};
          }
          svg {
            path {
              fill: #222222;
            }
          }
        }
      `;
    }
  }}
`;

export const AllMenuContainer = styled.div`
  display: none;
  align-items: center;
  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;
  }
`;

export const Divider = styled.span`
  margin-inline: 12px 6px;

  color: #001246;
  font-size: 15px;
  line-height: 18px;
`;
