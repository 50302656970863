import styled from "@emotion/styled";

import LogoIcon from "./icon-logo.svg";

export const Container = styled.div`
  inline-size: 74.83px;
  block-size: 28px;
  padding-block-start: 8px;

  text-align: center;

  border: 1px solid #001246;
  border-radius: 2px;
`;

export const StyledLogo = styled(LogoIcon)`
  inline-size: 54.83px;
  block-size: 13.97px;
`;
