import type { FunctionComponent, ReactNode } from "react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import type { SwiperProps } from "swiper/react";

import { StyledSwiper } from "./styles";

type Props = {
  children?: ReactNode;
  className?: string;
} & SwiperProps;

export const Swiper: FunctionComponent<Props> = ({ children, className, ...props }) => (
  <StyledSwiper
    className={className}
    modules={[Navigation, Pagination]}
    pagination={{
      clickable: true,
    }}
    cssMode
    navigation
    {...props}
  >
    {children}
  </StyledSwiper>
);

Swiper.displayName = "Swiper";
