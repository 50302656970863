import styled from "@emotion/styled";

import { EntityLink } from "scmp-app/components/entity-link";
import { SectionStyleHeaderMenuItem } from "scmp-app/components/section/section-style/section-style-header/section-style-header-menu-item";

export const StyledEntityLink = styled(EntityLink)`
  padding-inline-start: 24px;

  white-space: nowrap;
  text-transform: uppercase;
`;
export const StyledSectionStyleHeaderMenuItem = styled(SectionStyleHeaderMenuItem)``;

export const Container = styled.div`
  display: flex;
  align-items: center;

  padding-inline-end: 20px;

  ${StyledEntityLink}:first-child {
    padding-inline-start: 0;
  }

  ${StyledEntityLink} {
    padding-inline-start: 24px;
  }

  ${props => props.theme.breakpoints.between("desktop", "mediumDesktop")} {
    padding-inline-end: 16px;

    ${StyledEntityLink} {
      padding-inline-start: 16px;
    }
  }

  ${StyledEntityLink}:nth-child(n+7) {
    display: none;
  }
`;
