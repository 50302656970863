import type { FunctionComponent } from "react";

import {
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";

import { Container, StyledLink } from "./styles";

export type Props = {
  className?: string;
};

export const HeaderAllMenuEpaper: FunctionComponent<Props> = ({ className }) => {
  const edition = useEditionValue();

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>ePaper</HeaderAllMenuTitle>
      <HeaderAllMenuItemLinkContainer>
        {/* update this when we can move ePaper items to drupal config
            in that case HeaderAllMenuItemLink should be used */}
        <StyledLink
          pathname="/epaper"
          target="_blank"
          onClick={() => {
            sendGA4Tracking({
              action: "click",
              category: "menu",
              customized_parameters: {
                action_type: "redirect",
                destination: "Epaper",
                edition,
                menu_type: "mega",
              },
              subcategory: "section",
            });
          }}
        >
          ePaper
        </StyledLink>
      </HeaderAllMenuItemLinkContainer>
    </Container>
  );
};

HeaderAllMenuEpaper.displayName = "HeaderAllMenuEpaper";
