import { css } from "@emotion/react";
import styled from "@emotion/styled";

import { Container as MyNewsContainer } from "scmp-app/components/header/header-my-news/styles";
import { MenuItem } from "scmp-app/components/header/header-oneline-menu-container/header-oneline-menu/styles";
import { Divider as OnelineMenuDivider } from "scmp-app/components/header/header-oneline-menu-container/styles";
import { ActionButton, StyledLogoText } from "scmp-app/components/header/styles";
import {
  alignmentCssVariableValues,
  setFullViewportWidthBackgroundStyles,
  setFullViewportWidthStyles,
  ZIndexLayer,
} from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

import {
  DynamicHeader,
  DynamicHomeHeader,
  DynamicHomeMiniHeader,
  DynamicPlusHeader,
  DynamicPostiesBrochureHeader,
  DynamicPostiesContentHeader,
  DynamicStyleContentHeader,
} from "./dynamics";
import type { AppBarVariant } from "./types";

export const FullWidthBackground = styled.div`
  ${setFullViewportWidthBackgroundStyles("#ffffff")}
  transition: background-color 0.3s ease-in;
`;

type AppBarContainerProps = {
  $appBarVariant?: AppBarVariant;
};

export const Container = styled.header<AppBarContainerProps>`
  inset-block-start: 0;
  z-index: ${ZIndexLayer.AppBar};

  ${props => {
    if (
      props.$appBarVariant === "scmp/generic-dark" ||
      props.$appBarVariant === "scmp/scroll-over"
    ) {
      return css`
        ${FullWidthBackground} {
          background-color: #000000;
        }

        ${OnelineMenuDivider}, ${MenuItem} {
          color: #ffffff;
        }

        ${MyNewsContainer} {
          border-color: rgba(255, 255, 255, 0.4);
        }

        ${ActionButton} svg path, ${StyledLogoText} path {
          fill: #ffffff;
        }

        ${ActionButton} span {
          color: #ffffff;
        }
      `;
    }
  }}

  ${props => {
    if (props.$appBarVariant === "scmp/scroll-over") {
      return css`
        ${FullWidthBackground} {
          background-color: #00000080;
        }
      `;
    }
    if (props.$appBarVariant === "scmp/plus") {
      return css`
        ${FullWidthBackground} {
          background-color: transparent;
        }
      `;
    }
  }}
`;

type AdSlotProps = {
  $responsiveVariants: ResponsiveVariants<{
    $height: number;
    $isVisible?: boolean;
    $padding: number;
  }>;
};

export const AdSlotContainer = styled.div<AdSlotProps>`
  ${setFullViewportWidthStyles()}
  box-sizing: content-box;

  background-color: #f6f6f6;

  overflow: hidden;
  ${props =>
    setResponsiveVariantStyles(
      props.$responsiveVariants,
      variant => css`
        display: ${variant.$isVisible ? "block" : "none"};

        block-size: ${`${variant.$height}px`};
        padding-block: ${`${variant.$padding}px`};
      `,
    )}
`;

export const HeaderBottomContainer = styled.div`
  ${setFullViewportWidthStyles()}
`;

export const StyledHeader = styled(DynamicHeader)`
  padding-inline: ${alignmentCssVariableValues.headerAlignmentPaddingInline};

  ${props => props.theme.breakpoints.only("tablet")} {
    ${setFullViewportWidthStyles}
  }
`;

export const StyledHomeHeader = styled(DynamicHomeHeader)`
  ${props => props.theme.breakpoints.only("tablet")} {
    ${setFullViewportWidthStyles}
  }
`;

export const StyledHomeMiniHeader = styled(DynamicHomeMiniHeader)`
  ${props => props.theme.breakpoints.only("tablet")} {
    ${setFullViewportWidthStyles}
  }
`;

export const StyledPostiesBrochureHeader = styled(DynamicPostiesBrochureHeader)`
  ${setFullViewportWidthStyles}

  padding-inline: 16px;
  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
`;

export const StyledPostiesContentHeader = styled(DynamicPostiesContentHeader)`
  ${setFullViewportWidthStyles}

  padding-inline: 16px;
  ${props => props.theme.breakpoints.up("tablet")} {
    padding-inline: 32px;
  }
`;

export const StyledStyleContentHeader = styled(DynamicStyleContentHeader)`
  ${props => props.theme.breakpoints.only("tablet")} {
    ${setFullViewportWidthStyles}

    padding-inline: ${alignmentCssVariableValues.headerAlignmentPaddingInline};
  }
`;

export const StyledPlusHeader = styled(DynamicPlusHeader)`
  ${setFullViewportWidthStyles}
`;
