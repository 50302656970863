import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { transientOptions } from "@product/scmp-sdk";

import { BaseLink } from "scmp-app/components/common/base-link";
import { cssVariableValues } from "scmp-app/lib/styles";
import type { ResponsiveVariants } from "scmp-app/lib/styles/responsive";
import { setResponsiveVariantStyles } from "scmp-app/lib/styles/responsive";

import { DynamicHeaderOnelineMenuContainer } from "./dynamics";
import IconSearch from "./header-icon/search.svg";
import LogoFlag from "./icon-logo-flag.svg";
import LogoText from "./icon-logo-text.svg";
import type { LogoVariant } from "./types";

export type ActionButtonProps = {
  $responsiveVariants: ResponsiveVariants<"show" | "hidden">;
};
export const ActionButton = styled.div<ActionButtonProps>`
  cursor: pointer;

  ${props =>
    setResponsiveVariantStyles(props.$responsiveVariants, variant => {
      switch (variant) {
        case "show":
          return css`
            display: block;

            margin-inline-start: 20px;
          `;
        case "hidden":
          return css`
            display: none;
          `;
      }
    })}
`;

export const StyledDynamicHeaderOnelineMenuContainer = styled(DynamicHeaderOnelineMenuContainer)`
  display: none;

  ${props => props.theme.breakpoints.up("desktop")} {
    display: flex;
  }
`;

export const AvatarContainer = styled.div`
  inline-size: 32px;
  block-size: 32px;

  ${props => props.theme.breakpoints.up("tablet")} {
    inline-size: 42px;
    block-size: 42px;
  }
`;

export const StyledLogoText = styled(LogoText)`
  display: inline-block;

  inline-size: 201px;
  block-size: 19px;
  margin-inline-start: 7px;
`;

export const StyledLogoFlag = styled(LogoFlag)`
  display: inline-block;

  inline-size: 33px;
  block-size: 24px;
`;

export type LogoProps = {
  $responsiveVariants: ResponsiveVariants<LogoVariant>;
};
export const StyledLogoLink = styled(BaseLink, { ...transientOptions })<LogoProps>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  ${props =>
    setResponsiveVariantStyles(
      props.$responsiveVariants,
      variant => css`
        ${StyledLogoText} {
          display: ${variant === "full" ? "inline-block" : "none"};
        }
      `,
    )}

  /* Remarks:
    Workaround for small mobile which designer is not accept to use mobile down
  */
  ${props => props.theme.breakpoints.down(361)} {
    ${StyledLogoText} {
      display: none;
    }
  }
`;

export const StyledIconSearch = styled(IconSearch)`
  inline-size: 25px;
  block-size: 24px;
`;

export const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  block-size: ${cssVariableValues.appBarHeight};
  box-sizing: border-box;

  background-color: transparent;

  ${props => props.theme.breakpoints.up("tablet")} {
    border-block-end: 1px solid #cccccc;
  }
`;

export const HeaderArticleSpeechContainer = styled.div``;
