import styled from "@emotion/styled";

export const ItemContainer = styled.div``;

export const Container = styled.div`
  display: flex;
  flex-flow: column wrap;
  gap: 23px;

  max-block-size: 500px;

  ${ItemContainer}:first-child {
    inline-size: 106px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      block-size: 100%;
    }
  }

  ${ItemContainer}:nth-child(2) {
    inline-size: 106px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      inline-size: 114px;
    }
  }

  ${ItemContainer}:nth-child(3) {
    inline-size: 107px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      inline-size: 114px;
    }
  }

  ${ItemContainer}:nth-child(4) {
    inline-size: 92px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      inline-size: 99px;
    }
  }

  ${ItemContainer}:nth-child(5) {
    inline-size: 101px;
    ${props => props.theme.breakpoints.up("mediumDesktop")} {
      inline-size: 116px;
    }
  }

  ${props => props.theme.breakpoints.up("mediumDesktop")} {
    max-block-size: 420px;
  }
`;
