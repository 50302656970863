import type { FunctionComponent } from "react";
import { graphql, useFragment } from "react-relay";

import type { Props as BaseEntityLinkProps } from "scmp-app/components/base-entity-link";
import {
  HeaderAllMenuItemLink,
  HeaderAllMenuItemLinkContainer,
  HeaderAllMenuTitle,
} from "scmp-app/components/header/header-oneline-menu-container/header-all-menu/common";
import { sendGA4Tracking } from "scmp-app/components/tracking/google-analytics-4/apis";
import { useEditionValue } from "scmp-app/lib/edition";
import type { headerAllMenuTopicsQuery$key } from "scmp-app/queries/__generated__/headerAllMenuTopicsQuery.graphql";

import { Container } from "./styles";

export type Props = {
  className?: string;
  queryParameter: BaseEntityLinkProps["query"];
  reference: headerAllMenuTopicsQuery$key;
};

export const HeaderAllMenuTopics: FunctionComponent<Props> = ({
  className,
  queryParameter,
  reference: reference_,
}) => {
  const queue = useFragment(
    graphql`
      fragment headerAllMenuTopicsQuery on Query {
        topicQueue: queue(filter: { name: $topicQueueName }) {
          items(first: 6) {
            edges {
              node {
                ... on Topic {
                  ...entityLink
                  name
                }
              }
            }
          }
        }
      }
    `,
    reference_,
  );

  const edition = useEditionValue();

  return (
    <Container className={className}>
      <HeaderAllMenuTitle>Topics</HeaderAllMenuTitle>
      <HeaderAllMenuItemLinkContainer>
        {queue.topicQueue?.items?.edges.map((edge, index) => (
          <HeaderAllMenuItemLink
            key={index}
            query={queryParameter}
            reference={edge.node}
            onClick={() => {
              sendGA4Tracking({
                action: "click",
                category: "menu",
                customized_parameters: {
                  action_type: "redirect",
                  edition,
                  menu_type: "mega",
                  topic: edge.node.name ?? "",
                },
                subcategory: "section",
              });
            }}
          >
            {edge.node.name}
          </HeaderAllMenuItemLink>
        ))}
      </HeaderAllMenuItemLinkContainer>
    </Container>
  );
};

HeaderAllMenuTopics.displayName = "HeaderAllMenuTopics";
